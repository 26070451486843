import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'user-management',
    loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'order-management',
    loadChildren: () => import('./pages/order-management/order-management.module').then(m => m.OrderManagementModule)
  },
  
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
