export const environment = {
    production: false,
    APP_URL: 'https://fash-oums.stg.fashquik.com/',
    API_URL: 'https://pms-api.stg.fashquik.com/api',
    OUMS_API_URL: 'https://oums-api.stg.fashquik.com/api',
    WMS_API_URL:'https://wms-api.stg.fashquik.com/api/',
    firebase: {
        apiKey: "AIzaSyDMMofaDPDtVOqMY0ukYAfB9DOfoAP9DSs",
        authDomain: "notification-a171c.firebaseapp.com",
        projectId: "notification-a171c",
        storageBucket: "notification-a171c.appspot.com",
        messagingSenderId: "893163002266",
        appId: "1:893163002266:web:3ef7f8805ab59acb58c223",
        measurementId: "G-SDWPEVHZMN",
    }
}