import { Action, ActionReducerMap } from '@ngrx/store';
import { DeviceDetectorResult } from 'device-detector-js';
import { AppActions, AppActionTypes } from './app.actions';

export const appFeatureKey = 'app';
export interface AppState {
    user: any;
    basicInfo: any;
    userToken: any;
    isLoading: boolean;
    deviceDetails: DeviceDetectorResult;
}

const initialState: AppState = {
    userToken: null,
    user: {},
    basicInfo: {},
    isLoading: false,
    deviceDetails: {
        client: {
            type: '',
            name: '',
            version: '',
            engine: '',
            engineVersion: ''
        },
        os: {
            name: '',
            version: '',
            platform: ''
        },
        device: {
            type: '',
            brand: '',
            model: ''
        },
        bot: null
    }
};

export const appReducer = (state: AppState = initialState, action: AppActions): AppState => {
    switch (action.type) {
        case AppActionTypes.UpdateUser:
            return {
                ...state,
                user: action.user || null
            };

        case AppActionTypes.UpdateLoaderVisibility:
            return {
                ...state,
                isLoading: action.visibility
            };

        case AppActionTypes.UpdateBasicInfo:
            return {
                ...state,
                basicInfo: action.basicInfo || null
            };

        case AppActionTypes.UpdateDeviceDetails:
            return {
                ...state,
                deviceDetails: action.deviceDetails
            };

        case AppActionTypes.Logout:
            return initialState; 

        default:
            return state;
    }
};
export const reducers: ActionReducerMap<{ app: AppState }, AppActions> = {
    app: appReducer
};
