import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.API_URL;
  private readonly TOKEN_KEY = 'access_token';

  constructor(private http: HttpClient) {}

  setAuthToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getAuthToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  clearAuthToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }


  login(credentials: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/superAdmin/login`, credentials).pipe(
      map((result: any) => {
        if (result && result.token) {
          this.setAuthToken(result.token);
        }
        return result;
      })
    );
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.getAuthToken()) {
      headers = headers.set('Authorization', `Bearer ${this.getAuthToken()}`);
    }
    return headers;
  }

  createPasswordApi(payLoad: any, token: string) {
    return this.http.post<any>(`${this.apiUrl}/superAdmin/confirm-password?token=${token}`, payLoad);
  }

  sendPasswordResetInstructions(email: string) {
    return this.http.post<any>(`${this.apiUrl}/password-reset`, { email });
  }
  getUserId(): string | null {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedData = JSON.parse(userData);
      return parsedData._id || null;
    }
    return null;
  }
}
